import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

class FirebaseServer {
  constructor() {
    // 專案連結配置
    let firebaseConfig = {
      apiKey: "AIzaSyD02O7Ej_k4jKXYH3CqgpElzGMtkEmzZsY",
      authDomain: "lele-lelelink.firebaseapp.com",
      projectId: "lele-lelelink",
      storageBucket: "lele-lelelink.appspot.com",
      messagingSenderId: "14181050739",
      appId: "1:14181050739:web:2757e4daaed086ae6e3d06",
      measurementId: "G-018SDY9472",
    };
    // 初始化 Firebase
    const app = initializeApp(firebaseConfig);
    // 初始化 FCM 服務
    this.messaging = getMessaging(app);
    // 設置消息監聽
    onMessage(this.messaging, (payload) => {
      console.log("[FCM] 收到的消息 : ", payload);

      let fun = this.onMessages?.[payload.data?.type];
      !fun || fun(payload);
    });
  }

  /** [推播] 獲取憑證
   * - 建議先使用 Notification.requestPermission() 詢問通知權限
   * @example
   * Notification.requestPermission().then((permission) => {
   *   if (permission !== "denied") {
   *      // 開始獲取 Token
   *      FirebaseServer.getToken().then((token) => {
   *        ...
   *      })
   *   }
   * });
   */
  getToken() {
    // 網路憑證
    let vapidKey = "BEtvqXgUX61M5Mux4_CehXRXfqGsAMNw4Mvs11l3Ms4hlwv9u8_pwHxJL4cJtQeLrCC38Id7evWDOkMYV2rCCd4";
    return getToken(this.messaging, { vapidKey });
  }

  /** [推播] 新增前景消息監聽
   * @param {string} type 監聽的類型(唯一、重複會覆蓋)
   * @param {function(payload): void} onMessage 回調收到的消息內容
   */
  addOnMessageListener(type, onMessage) {
    if (!this.onMessages) {
      /** 外部設置的監聽事件 <k : type, v : onMessage>*/
      this.onMessages = {};
    }
    this.onMessages[type] = onMessage;
  }

  /** [推播] 刪除前景消息監聽
   * @param {string} type 監聽的類型
   */
  removeOnMessageListener(type) {
    this.onMessages[type] = null;
  }
}

// 單例配置
if (!FirebaseServer.instance) {
  FirebaseServer.instance = new FirebaseServer();
}
export default FirebaseServer.instance;
